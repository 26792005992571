<template>
  <v-row>
    <v-row justify="center" max-width="344">
      <v-col cols="12" sm="11">
        <v-form
          ref="form"
          v-model="isvalid"
          lazy-validation
        >
          <v-card>
						<v-card-title align="left" class="headline primary create-disb-title">
							<h5>Create Cash Receipt</h5>
						</v-card-title>     
            <v-container grid-list-md class="create-disb-container">
							<h4>Cash Receipt Info</h4>
							<br>
							<v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field label="Batch Number" v-model="batch_number" :rules="batch_number_rules" required></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
									<v-menu
										v-model="batch_datepicker"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="290px"
										:close-on-content-click="false"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												label="Date"
												v-mask="date_format"
												v-model="date"
												required
												readonly
												:rules="date_rules"
												return-masked-value
												v-on="on"
												mask="YYYY-MM-DD"
												placeholder="YYYY-MM-DD">
											</v-text-field>
										</template>
										<v-date-picker
											color="primary"
											v-model="date"
											@change="batchDatepickerSelect(date)"
										></v-date-picker>
									</v-menu>
                </v-flex>
                <v-flex xs12 sm6 md4>
									<v-autocomplete
										v-model="bank_account_id"
										:items="dataBanks"
										editable
										hide-details
										item-text="code_name"
										item-value="id"
										label="Bank"
									>
									<template slot="item" slot-scope="data">
										{{ data.item.bank_code }} - {{ data.item.bank_acct }} - {{ data.item.acct_type }} - {{data.item.acct_clss}}
									</template>
									</v-autocomplete>
                </v-flex>
								<v-flex xs12 sm6 md4>
									<v-autocomplete
										v-model="currency_id"
										:items="currency"
										editable
										hide-details
										item-text="cur_desc"
										readonly
										item-value="id"
										label="Currency"
									></v-autocomplete>
                </v-flex>
								<v-flex xs12 sm6 md4>
                  <v-text-field label="Rate" v-model="dollar_rate" v-if="currency_id !== 1"></v-text-field>
                </v-flex>
              </v-layout>
							<v-checkbox 
								v-model="isConfidential" 
								label="Save this cash receipt form as Confidential?"
								false-value="0"
								true-value="1"
							></v-checkbox>
							<v-layout wrap>
								<v-flex xs12 sm12 md12>
									<p style="font-size:12px; color:orange;line-height:0; margin:0">
										<v-icon color="orange" x-small>mdi-alert-circle-outline</v-icon>
										Please make sure to set the correct document date before selecting Receipt Type.
									</p>
									Receipt Type
								</v-flex>
								<v-flex xs12 sm6 md2>
									<v-checkbox 
										v-model="no_receipt" 
										label="No Receipt"
										false-value="0"
										true-value="1"
										:readonly="nr_readonly"
									></v-checkbox>
								</v-flex>
								<v-flex xs12 sm6 md2>
									<v-checkbox 
										v-model="official_receipt" 
										label="Service Invoice"
										false-value="0"
										true-value="1"
									></v-checkbox>
								</v-flex>
								<v-flex xs12 sm6 md3>
									<v-checkbox 
										v-model="acknowledgement_receipt" 
										label="Acknowledgement Receipt"
										false-value="0"
										true-value="1"
									></v-checkbox>
								</v-flex>
							</v-layout>
							<div class="container">
								<div class="large-12 medium-12 small-12 cell">
									<label>Files
										<input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()"/>
									</label>
								</div>
							</div>
            </v-container>

            <v-divider class="create-disb-divider"></v-divider>
            <v-container class="doc-items-container" grid-list-md>
              <h4>Accounting Entries</h4>
              <p class="caption"></p>
              <trx-header ref="trxHeader" 
								:batch_number.sync="batch_number" 
								:batch_document_date.sync="date" 
								:method_type.sync="method_type" 
								:headerValue.sync="headers" 
								:detailValue.sync="details" 
								:document_type.sync="document_type" 
								:bank_account_id.sync="bank_account_id" 
								:newDetail.sync="newDetail" 
								:currency_id.sync="currency_id" 
								:dollar_rate.sync="dollar_rate" 
								:rec_ent.sync="rec_ent" :count.sync="count"
								:dataBanks="dataBanks"
								:dataRegularTrx="dataRegularTrx"
								:dataDepts="dataDepts">
							</trx-header>
            </v-container>
            <v-divider class="create-disb-divider"></v-divider>
            <v-card-actions class="card-action-container">
							<div>
								<v-btn text color="blue accent-4" @click="createDialog = true">Create Cash Receipt</v-btn>
								<v-btn text color="black accent-4" @click="draftCashReceipt = true">Save as Draft</v-btn>
								<v-btn dark color="red accent-4" @click="removeStorage('cancel')">Cancel</v-btn>
							</div>
              <!-- <v-btn right @click="generatePDF">Generate PDF (WIP)</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

		<!-- Dialog -->
		<div class="text-center">
			<v-dialog
				v-model="createDialog"
				persistent max-width="600"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						Create Cash Receipt
					</v-card-title>
					<br>
					<v-card-text>
						Are you sure you want to submit the form?
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="save('create')"
						>
							Save
						</v-btn>
						<v-btn
							color="red"
							text
							@click="createDialog = false"
						>
							Cancel
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<div class="text-center">
			<v-dialog
				v-model="draftCashReceipt"
				persistent max-width="600"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						Save As Draft
					</v-card-title>
					<br>
					<v-card-text>
						Are you sure you want to save the form as Draft?
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="save('draft')"
						>
							Save as Draft
						</v-btn>
						<v-btn
							color="red"
							text
							@click="draftCashReceipt = false"
						>
							Cancel
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<div class="text-center">
			<v-dialog
				v-model="createDisbDialog"
				persistent max-width="600"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						Create Cash Receipt
					</v-card-title>
					<br>
					<v-card-text>
						Sorry, the accounting period for this month is already <b>CLOSED</b>.
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<router-link to="/trxcr">
							<v-btn
								color="primary"
								text
								@click="createDisbDialog = false"
							>
								OK
							</v-btn>
						</router-link>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>

		<!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      right
    >
      {{ error_message }}
      <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
	</v-row>
</template>
<script>
import trxHeader from './createCashReceiptHeader';
import { mask } from 'vue-the-mask';
import { mapGetters } from 'vuex';
import { format, getMonth, getYear, parseISO, addMonths } from 'date-fns';
import jsonToFormData from 'json-form-data';

export default {
	directives: {
		mask,
	},
	components: {
		'trx-header': trxHeader,
	},
	data() {
		return {
			level1: {},
			level2: {},
			level3: {},
			official_receipt: 0,
			acknowledgement_receipt: 0,
			no_receipt: '1',
			nr_readonly: true,
			currency_id: 1,
			dollar_rate: '',
			rec_ent: 0,
			count: 0,
			batch_datepicker: false,
			newDetail: {},
			document_type: 'CR',
			show: false,
			isvalid: false,
			batch_number: '',
			draftCashReceipt: false,
			date_format: '####-##-##',
			date: '',
			upload_files: '',
			upload_file: [],
			bank_account_id: 0,
			snackbar: false,
			isConfidential: '0',
			method_type: '',
			error_message: 'Error: Please fill out the required fields',
			headers:[],
			details:[],
			generated_receipt: [],
			image_title: [],
			file: '',
			createDialog: false,
			createDisbDialog: false,
			nextPageDepts: '',
			nextPagePayee:'',
			nextPageAcctmainperiod:'',
			nextPageBanks:'',
			dataBanks: [],
			dataAcctmainperiod: [],
			dataDepts: [],
			dataPayee: [],
			dataRegularTrx: [],
			nextPageRegularTrx: '',
			batch_number_rules: [
				v => !!v || 'Batch Number is required',
			],
			date_rules: [
				v => !!v || 'Date is required',
			],
			payee_rules: [
				v => !!v || 'Payor is required',
			],
			bank_rules: [
				v => !!v || 'Bank is required',
			],
		};
	},
	computed: {
		...mapGetters({
			banks: 'bank/banks',
			currUser: 'auth/currUser',
			success: 'trxdisb/success',
			dist_data: 'trxdisb/dist_data',
			depts: 'dept/depts',
			payeefile: 'payefile/payefiles',
			acctperiodmains: 'acctperiodmain/acctperiodmains',
			currency: 'currency/currencies',
			regulartrx: 'regularTxns/regulartxns',
			approvinglevel: 'approvinglevel/approvinglevels',
			coas: 'coa/coaSubs',
			coaData: 'coa/coaData',
			or_number: 'trxdisb/or_number',
			ar_number: 'trxdisb/ar_number'
		}),
		payees(){
			let data = this.payeefile.filter(e => e.status === 1);
			return data;
		}
	},
	watch: {
		approvinglevel: {
			handler(val) {
				val.forEach(level => {
					if (level.level_code == 1){
						this.level1 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					} else if (level.level_code == 2){
						this.level2 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					} else if (level.level_code == 3){
						this.level3 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					}
				});
			}
		},
		coas: {
			handler(){
				this.getDataCoas();
			}
		},
		document_type: {
			handler(val) {
				this.setBatchNumber(val);
			}
		},
		batch_document_date: {
			handler() {
				this.setStorage();
			}
		},
		batch_number: {
			handler() {
				this.setStorage();
			}
		},
		bank_account_id: {
			handler() {
				this.setStorage();
				this.getDefaultCurrency();
			}
		},
		isConfidential: {
			handler() {
				this.setStorage();
			}
		},
		currency_id: {
			handler() {
				this.setStorage();
			}
		},
		dollar_rate: {
			handler() {
				this.setStorage();
			}
		},
		official_receipt: {
			handler(val) {
				this.getDocument('OR', val);
				this.setStorage();
			}
		},
		acknowledgement_receipt: {
			handler(val) {
				this.getDocument('AR', val);
				this.setStorage();
			}
		},
		no_receipt: {
			handler(val) {
				this.getDocument('NR', val);
				this.setStorage();
			}
		},
		banks: {
			handler() {
				this.getDataBank();
			}
		},
		depts: {
			handler() {
				this.getDataDepts();
			}
		},
		acctperiodmains: {
			handler() {
				this.getAcctperiodmain();
			}
		},
		regulartrx: {
			handler() {
				this.getRegTrx();
			}
		}
	},
	mounted() {
		//Gets all the dropdown data for the following items
		this.$store.dispatch('bank/getBanks');
		this.$store.dispatch('dept/getDeptDropdown');
		this.$store.dispatch('payefile/getPayefileDropdown');
		this.$store.dispatch('currency/getCurrencies');
		this.$store.dispatch('regularTxns/getregularTxns');
		this.$store.dispatch('approvinglevel/getApprovingLevels');
		this.$store.dispatch('acctperiodmain/getAcctperiodmains');
		this.$store.dispatch('coa/getCoaDropdown');

		
		//Gets all the data needed for checking
		let dateFormat = this.getCurrentDate();
		let currentMonth = getMonth(parseISO(dateFormat));
		let currentYear = getYear(parseISO(dateFormat));
		let isBookOpen = true;

		//Initializes the function
		this.checkBookStatus(isBookOpen, currentMonth, currentYear, dateFormat);
	},
	methods: {
		init() {
			this.$refs.trxHeader.addHeader;
			this.headers = this.$data.headerSubmitContent;
			this.details = this.$data.details;
		},
		getCurrentDate() {
			return format(new Date(), 'yyyy-MM-dd');
		},
		checkBookStatus(isBookOpen, currentMonth, currentYear, dateFormat) {
			//Checks the current Account Period
			this.dataAcctmainperiod.forEach(account_period => {
				if (account_period.period_no === currentMonth && account_period.fiscal_year === currentYear) {
					if (account_period.cash_receipt === 'CLOSED') {
						isBookOpen = false;
					} else {
						isBookOpen = true;
					}
				} else {
					isBookOpen = true;
				}
			});

			//If the book for the current and future month is open
			if (isBookOpen === true) {
				this.$data.date = dateFormat;
				
				const distData = this.dist_data;
				
				let data = distData.cash_receipt;
				
				this.$data.batch_number = data.batch_number;
				
				if (data.date){
					this.$data.date = data.date;
				}

				if (data.bank_account_id){
					this.$data.bank_account_id = data.bank_account_id;
				}

				if (data.isConfidential){
					this.$data.isConfidential = data.isConfidential;
				}

				if (data.disbursement_type) {
					this.$data.disbursement_type = data.disbursement_type;
				}

				if (data.currency_id) {
					this.$data.currency_id = data.currency_id;
				}

				if (data.dollar_rate) {
					this.$data.dollar_rate = data.dollar_rate;
				}
				
				if (data.no_receipt) {
					this.$data.no_receipt = data.no_receipt;
				}

				if (data.acknowledgement_receipt) {
					this.$data.acknowledgement_receipt = data.acknowledgement_receipt;
				}
				if (data.official_receipt) {
					this.$data.official_receipt = data.official_receipt;
				}

				this.setStorage(); 
			} else {
				this.createDisbDialog = true;
			}
		},
		batchDatepickerSelect(date) {
			if (date) {
				let month = getMonth(parseISO(date));
				let year = getYear(parseISO(date));
				this.dataAcctmainperiod.forEach(account_period => {
					if (account_period.period_no === (month + 1) && account_period.fiscal_year === year) {
						if (account_period.cash_receipt === 'CLOSED') {
							this.snackbar = true;
							this.$data.error_message = 'Accounting Period for this date is already Closed.';
							this.date = this.getCurrentDate();
						}
					}
				});
				this.batch_datepicker = false;
			}
		},
		setExistingBatchDetails(disb_type, dist_data, current_date) {
			let data = dist_data[disb_type];
			this.$data.batch_number = data.batch_number;
			this.$data.bank_account_id = data.bank_account_id;
			this.$data.isConfidential = data.isConfidential;
			this.$data.date = current_date;
			this.$data.currency_id = data.currency_id;
			this.$data.dollar_rate = data.dollar_rate;
			this.$data.no_receipt = data.no_receipt;
			this.$data.acknowledgement_receipt = data.acknowledgement_receipt;
			this.$data.official_receipt = data.official_receipt;
		},
		setStorage() {
			let distData = this.dist_data;
			let data = [];
			data = distData.cash_receipt;
			data['document_type'] = this.document_type;
			data['date'] = format(new Date(), 'yyyy-MM-dd');
			data['bank_account_id'] = this.bank_account_id;
			data['isConfidential'] = this.isConfidential;
			data['currency_id'] = this.currency_id;
			data['dollar_rate'] = this.dollar_rate;
			data['no_receipt'] = this.no_receipt;
			data['acknowledgement_receipt'] = this.acknowledgement_receipt;
			data['official_receipt'] = this.official_receipt;
			
			this.$store.commit('trxdisb/SET_DIST_DATA', distData);
		},
		getDefaultCurrency() {
			let acct_class = '';
			this.dataBanks.filter(det => {
				if(det.id === this.bank_account_id){
					acct_class = det.acct_clss;
				}
			});

			this.currency.filter(det => {
				if(det.cur_code === acct_class){
					this.currency_id = det.id;
				}
			});
		},
		removeStorage(from) {
			let data = this.dist_data;
			let fields = ['details', 'headers', 'date', 'bank_account_id', 'isConfidential', 'document_type','currency_id', 'dollar_rate', 'no_receipt', 'acknowledgement_receipt', 'official_receipt', 'nr', 'or', 'ar'];
			
			if('cash_receipt' in data){
				this.deleteStorageType(data, 'cash_receipt', fields);
			}

			if (from == 'saving'){
				delete data.cash_receipt;
			}
			this.$store.commit('trxdisb/SET_DIST_DATA', data);
			this.$router.push('/trxcr');
		},
		deleteStorageType(data, disbursement_type, fields) {
			fields.forEach(field => {
				if(field in data[disbursement_type]){
					delete data[disbursement_type][field];
				}
			});
		},
		handleFileUploads(){
			this.upload_files = this.$refs.files.files;
		},
		async generateReceipt(counter, due_date, header, details) {
			let currentDueDate = format(new Date(due_date), 'yyyy-MM-dd');

			const distData = this.dist_data;
			let header_data = [];

			let data = { 
				document_date: format(new Date(), 'yyyy-MM-dd'),
				document_type: this.document_type,
				edit_by: this.currUser.id
			};

			for (let i = 0; i < counter; i++) {
				let due_date = addMonths(parseISO(currentDueDate), i + 1);

				let headerDoc = [];

				header_data.forEach((headerDocNum) => {
					headerDoc.push(headerDocNum.document_number);
				});

				let datas = [];
				let detailData = {};

				distData.cash_receipt.document_number.map(i => {
					detailData = {document_number: parseInt(i), matched: headerDoc.includes(parseInt(i))};
					datas.push(detailData);
				});

				let num = datas.filter(det => {
					return det.matched === false; 
				});

				let unique_id = '';
				let get_generated_number = '';

				if(num.length !== 0){
					unique_id = num[0].document_number;
				}

				
				if(!unique_id){
					if(this.no_receipt == 1){
						data['batch_number'] = distData.cash_receipt.batch_number;
						get_generated_number = this.$store.dispatch('trxdisb/getDocumentNumber', data).then(response => {
							let data = distData.cash_receipt;
							data['document_number'].push(response.data.data.document_number);
							unique_id = parseInt(response.data.data.document_number);
						});
					} else if (this.official_receipt == 1){
						if(!this.or_number){
							get_generated_number = this.$store.dispatch('trxdisb/getDocumentNumberOR').then(response => {
								let data = distData.cash_receipt;
								data['document_number'].push(response.data.data.or_number);
								unique_id = parseInt(response.data.data.or_number);
							});
							this.$store.dispatch('trxdisb/setOR', unique_id);
						} else {
							unique_id = this.or_number;
						}
					} else if (this.acknowledgement_receipt == 1){
						if(!this.ar_number){
							get_generated_number = this.$store.dispatch('trxdisb/getDocumentNumberAR').then(response => {
								let data = distData.cash_receipt;
								data['document_number'].push(response.data.data[0].ar_number);
								unique_id = parseInt(response.data.data.ar_number);
							});
							this.$store.dispatch('trxdisb/setAR', unique_id);
						} else {
							unique_id = this.ar_number;
						}
					}
				}

				await get_generated_number;

				let detail_values = [];
				
				details.forEach(detail => {
					detail_values.push({
						section_id: detail.section_id,
						regionalhead_id: detail.regionalhead_id,
						document_number: unique_id,
						document_date: this.$data.date,
						account_code: detail.account_code,
						debit_amount: detail.debit_amount,
						credit_amount: detail.credit_amount,
						branch_id: detail.branch_id,
						remarks: detail.remarks,
						reference_number: unique_id,
						payee_id: detail.payee_id,
						department_id: detail.department_id,
					});
				});

				let header_value = {
					company_id: header.company_id,
					batch_number: header.batch_number,
					regular_trx_type: header.regular_trx_type,
					disbursement_type: header.disbursement_type,
					regular_trx_selected: header.regular_trx_id,
					document_number: unique_id,
					atc_code: header.atc_code,
					currency_id: header.currency_id,
					dollar_rate: header.dollar_rate,
					vat: header.vat,
					amount: header.amount,
					regular_trx_id: header.regular_trx_id,
					enable_tax_base: header.enable_tax_base,
					check_type: header.check_type,
					payee_bank: header.payee_bank,
					due_date: format(new Date(due_date), 'yyyy-MM-dd'),
					reference_number: unique_id,
					details: detail_values,
					overallDebit: header.overallDebit,
					overallCredit: header.overallCredit,
					payee_id: header.payee_id,
					payee_account_id: header.payee_account_id,
					remarks: header.remarks,
					document_date: this.$data.date,
				};

				header_data.push(header_value);
				this.generated_receipt.push(header_value);
			}

			return new Promise(resolve => {
				setTimeout(() => {
					resolve(this.generated_receipt);
				}, 1500);
			});
		},
		async save(type) {
			//Validations
			this.isvalid = this.$refs.form.validate();

			if (this.isvalid) {
				//Initialize Payload
				let data = {};
				let detail = [];
				let header = [];
				let distData = {};
				let dollar_rate = 0;
				let is_or_ar = 0;
				let ar_number = 0;
				let or_number = 0;
				let get_generated_receipt = [];
				this.generated_receipt = [];

				data = {};
				header = [];
				detail = [];
				data.image_title = [];
				data.upload_file = [];

				detail = this.details;

				data.headers = header;
				data.details = detail;

				distData = this.dist_data;
				
				if (this.official_receipt == 1) {
					or_number = distData.cash_receipt.or.headers[0].document_number;
					is_or_ar = 1;
					if (this.acknowledgement_receipt == 1) {
						ar_number = distData.cash_receipt.ar.headers[0].document_number;
						is_or_ar = 3;
					}
				} else if (this.acknowledgement_receipt == 1) {
					ar_number = distData.cash_receipt.ar.headers[0].document_number;
					is_or_ar = 2;
					if (this.official_receipt == 1) {
						or_number = distData.cash_receipt.or.headers[0].document_number;
						is_or_ar = 3;
					}
				}

				const filterRegTrx = this.headers.filter(header => header.disbursement_type === '1');
				
				if (filterRegTrx.length > 0) {
					this.saveRegularTrx(filterRegTrx, data);
				}

				var updatedDetails = [];

				this.headers.forEach((headerData) => {
					let payeeItemsHeaders = headerData.payee_id;

					if (typeof headerData.payee_id === 'number') {
						payeeItemsHeaders = headerData.payee_id;
					} else if (typeof headerData.payee_id === 'object') {
						payeeItemsHeaders = headerData.payee_id.id;
					}

					let headerObject = {
						company_id: headerData.company_id,
						batch_number: this.$data.batch_number,
						document_number: headerData.document_number,
						ar_number: ar_number,
						or_number: or_number,
						is_or_ar: is_or_ar,
						atc_code: headerData.atc_code ? headerData.atc_code : 0,
						currency_id: this.$data.currency_id,
						dollar_rate: this.$data.dollar_rate ? this.$data.dollar_rate : dollar_rate,
						vat: headerData.vat,
						amount: headerData.amount,
						regular_trx_id: headerData.regular_trx_type === '1' ? headerData.regular_trx_id : (headerData.regular_trx_type === '2' ? process.env.VUE_APP_REGULAR_TRX_ID_PLACEHOLDER : 0),
						overallDebit: headerData.overallDebit.replace(/,/g, ''),
						overallCredit: headerData.overallCredit.replace(/,/g, ''),
						payee_id: payeeItemsHeaders,
						remarks: headerData.remarks,
						status: this.getStatus(type, headerData.auto_debit),//type === 'create' ? ((headerData.auto_debit == '1') ? 6 : 3) : 1,
						document_date: this.$data.date,
						is_pdc: 0,
						is_confidential: this.$data.isConfidential,
						document_type: this.$data.document_type,
						bank_account_id: this.$data.bank_account_id,
						regtrx_indicator: headerData.regular_trx_type,
						edit_by: this.currUser.id,
						payee_account_id: headerData.payee_account_id ? headerData.payee_account_id : 0,
						reference_number: headerData.document_number,
						due_date: headerData.due_date,
						auto_debit: (headerData.auto_debit !== undefined) ? headerData.auto_debit : 0
					};

					if(headerObject.payee_account_id === '' || headerObject.payee_account_id === undefined){
						delete headerObject.payee_account_id;
					}

					header.push(headerObject);

					const filterDetails = this.details.filter(detail => detail.document_number === headerData.document_number);					

					filterDetails.forEach((detail) => {
						detail.debit_amount = detail.debit_amount.replace(/,/g, '');
						detail.credit_amount = detail.credit_amount.replace(/,/g, '');
						detail.remarks = detail.remarks ? detail.remarks : 'New Entry';

						updatedDetails.push({
							batch_number: this.$data.batch_number,
							account_code: detail.account_code,
							branch_id: detail.branch_id,
							credit_amount: detail.credit_amount,
							debit_amount: detail.debit_amount,
							department_id: detail.department_id,
							document_date: this.$data.date,
							document_number: detail.document_number,
							payee_id: detail.payee_id,
							regionalhead_id: detail.regionalhead_id,
							currency_id: detail.currency_id,
							remarks: detail.remarks,
							edit_by: this.currUser.id,
							section_id: detail.section_id,
							reference_number: detail.document_number
						});

						if(detail.section_id === '' || detail.section_id === undefined){
							detail.section_id = 0;
						}

						//this.$refs.trxHeader.setDetailValue(detail.payee_id, index, detail.document_number, detail.section_id);
						
					});

					data.details = updatedDetails;

					if(this.rec_ent === 1){
						get_generated_receipt = this.generateReceipt(this.count, headerData.due_date, headerObject, updatedDetails);
					}
				});
				
				if (this.rec_ent === 1) {
					this.$store.commit('app/SET_IS_LOADING', true, { root: true });
					await get_generated_receipt;
					this.$store.commit('app/SET_IS_LOADING', false, { root: true });

					if (parseInt(this.count) === this.generated_receipt.length) {
						this.generated_receipt.splice(0, 1);
						this.generated_receipt.forEach(headerData => {
							let payeeItemsHeaders = headerData.payee_id;

							if (typeof headerData.payee_id === 'number') {
								payeeItemsHeaders = headerData.payee_id;
							} else if (typeof headerData.payee_id === 'object') {
								payeeItemsHeaders = headerData.payee_id.id;
							}

							data.headers.push({
								company_id: headerData.company_id,
								batch_number: this.$data.batch_number,
								document_number: headerData.document_number,
								atc_code: headerData.atc_code ? headerData.atc_code : 0,
								currency_id: this.$data.currency_id,
								dollar_rate: this.$data.dollar_rate,
								is_pdc: headerData.is_pdc,
								vat: headerData.vat,
								amount: headerData.amount,
								regular_trx_id: headerData.regular_trx_type === '1' ? headerData.regular_trx_id : (headerData.regular_trx_type === '2' ? process.env.VUE_APP_REGULAR_TRX_ID_PLACEHOLDER : 0),
								overallDebit: headerData.overallDebit.replace(/,/g, ''),
								overallCredit: headerData.overallCredit.replace(/,/g, ''),
								payee_id: payeeItemsHeaders,
								remarks: headerData.remarks,
								status: this.getStatus(type, headerData.auto_debit),//type === 'create' ? ((headerData.auto_debit == '1') ? 6 : 3) : 1,
								document_date: this.$data.date,
								is_confidential: this.$data.isConfidential,
								document_type: this.$data.document_type,
								bank_account_id: this.$data.bank_account_id,
								regtrx_indicator: headerData.regular_trx_type,
								edit_by: this.currUser.id,
								payee_account_id: headerData.payee_account_id,
								reference_number: headerData.document_number,
								due_date: headerData.due_date,
								auto_debit: (headerData.auto_debit !== undefined) ? headerData.auto_debit : 0,
							});
							headerData.details.forEach(detail => {
								data.details.push({
									batch_number: this.$data.batch_number,
									account_code: detail.account_code,
									branch_id: detail.branch_id,
									credit_amount: detail.credit_amount,
									debit_amount: detail.debit_amount,
									department_id: detail.department_id,
									document_number: detail.document_number,
									document_date:this.$data.date,
									payee_id: detail.payee_id,
									regionalhead_id: detail.regionalhead_id,
									currency_id: detail.currency_id,
									remarks: detail.remarks,
									edit_by: this.currUser.id,
									section_id: detail.section_id,
									reference_number:  detail.document_number
								});
							});
						});
					}					
				}

				for( var i = 0; i < this.upload_files.length; i++ ){
					let file = this.upload_files[i];
					data.image_title.push(file.name);
					data.upload_file.push(file);
				}
				
				switch(type) {
				case 'create':
					this.$data.method_type = 'Save';
					this.executeEntries(data.details, data, 'save-cash-receipt', 'cash-receipt');
					break;
				case 'draft':
					this.$data.method_type = 'Save as Draft';
					this.executeEntries(data.details, data, 'save-cash-receipt-draft', 'cash-receipt');
					break;
				}
			} else {
				this.snackbar = true;
				this.createDialog = false;
			}
		},
		saveRegularTrx(filterRegTrx, data) {
			let regularTrx = {};

			filterRegTrx.forEach((trxData) => {
				const filterDetails = data.details.filter(detail => detail.document_number === trxData.document_number);
				let acctEntriesData = [];
				acctEntriesData = [];

				let payeeItems = trxData.payee_id;

				if (typeof trxData.payee_id === 'number') {
					payeeItems = trxData.payee_id;
				} else if (typeof trxData.payee_id === 'object') {
					payeeItems = trxData.payee_id.id;
				}

				
				filterDetails.forEach((detail) => {
					let sec_id = detail.section_id;
					
					if (detail.section_id === '' || detail.section_id === undefined){
						sec_id = 0;
					}

					acctEntriesData.push({
						'analysis': detail.payee_id,
						'department': detail.department_id,
						'currency_id': detail.currency_id,
						'branch': detail.branch_id,
						'section': sec_id,
						'account_code': detail.account_code,
						'debit': parseFloat(detail.debit_amount.replace(/,/g, '')),
						'credit': parseFloat(detail.credit_amount.replace(/,/g, ''))
					});
				});

				regularTrx = {
					txn_type: trxData.txn_type,
					payee_name: '1',
					payee_id: payeeItems,
					payee_bank_id: '1',
					payee_bank_name: '1',
					atc_code: trxData.atc_code,
					atc_rate: '10.5500',
					remarks: trxData.remarks,
					document_type: this.$data.document_type,
					acct_entries: JSON.stringify(acctEntriesData),
					edit_by: this.currUser.id
				};

				if (trxData.regular_trx_type === '2') {
					regularTrx.doc_number = trxData.document_number;
					regularTrx.is_approved = 0;

					this.executeEntries(filterDetails, regularTrx, 'save-regulartrx', 'regular-trx');
				} else {
					regularTrx.id = trxData.regular_trx_id;

					this.executeEntries(filterDetails, regularTrx, 'update-regulartrx', 'regular-trx');
				}

				if(filterRegTrx.payee_account_id === '' || filterRegTrx.payee_account_id === undefined){
					delete filterRegTrx.payee_account_id;
				}
			});
		},
		executeEntries(details, payload, method, type) {
			//Initialize Checkers
			let hasDepts = [];
			let hasBranches = [];
			let hasSection = [];
			let unBalanced = [];
			let hasPayeeBank = [];
			let hasCOA = [];
			let hasRemarks = [];
			let diffCurrency = [];

			details.forEach(detail => {
				if (detail.department_id === undefined || detail.department_id === '' || detail.department_id === 0) {
					hasDepts.push({dept: false});
				}

				if (detail.branch_id === undefined || detail.branch_id === '') {
					hasBranches.push({branch: false});
				} 

				if(detail.account_code === undefined || detail.account_code === ''){
					hasCOA.push({coa: false});
				}
				
				if (detail.department_id){
					let det = this.dataDepts.filter(dept => { return dept.id === detail.department_id; });
					if(det.length !== 0){
						if(det[0].sections.length !== 0 && detail.section_id === '' || det[0].sections.length !== 0 && detail.section_id === undefined){
							hasSection.push({department: det[0].dept_desc, section: false});
						}
					}
				}

				if(detail.account_code === undefined || detail.account_code === ''){
					hasCOA.push({coa: false});
				} 

				if(detail.account_code){
					let det = this.coaData.filter(coa => {return coa.acct_code === detail.account_code;});
					if(det[0].currency_id !== this.currency_id){
						diffCurrency.push({currency: false});
					}
					detail.currency_id = det[0].currency_id;
				}
			});

			switch(type) {
			case 'regular-trx':
				if(payload.remarks === '' || payload.remarks === undefined){
					hasRemarks.push({remarks: true});
				}

				if(payload.atc_code === '' || payload.atc_code === undefined || payload.atc_code === null){
					payload.atc_code = 0;
				}
				
				break;
			case 'cash-receipt':
				payload.headers.forEach(headerObject => {
					if(headerObject.overallDebit != headerObject.overallCredit){
						unBalanced.push({unbal: true});
					}

					if(headerObject.remarks === '' || headerObject.remarks === undefined){
						hasRemarks.push({remarks: true});
					}

					if(headerObject.payeeItemsHeaders){
						let pay = this.payees.filter(pay => { return pay.id === headerObject.payeeItemsHeaders; });
						if (pay[0].payee_banks.length !== 0) {
							if(headerObject.payee_account_id === '' || headerObject.payee_account_id === undefined){
								hasPayeeBank.push({payee: false});
							}
						}
					}
				});
				break;
			}

			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};

			var convertedFormData = jsonToFormData(payload, options);

			if (hasDepts.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'Department is missing from the field.';
				this.createDialog = false;
			} else if (hasBranches.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'Branch is missing from the field.';
				this.createDialog = false;
			} else if (hasSection.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'Section is required in '+ hasSection[0].department +'.';
				this.createDialog = false;
			} else if (hasCOA.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'COA is missing from the field.';
				this.createDialog = false;
			} else if(unBalanced.length != 0 && method != 'save-cash-receipt-draft'){
				this.snackbar = true;
				this.$data.error_message = 'Unbalanced Entries.';
				this.createDialog = false;
			} else if (hasRemarks.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'Remarks is missing from the field.';
				this.createDialog = false;
			} else if(hasPayeeBank.length != 0){
				this.snackbar = true;
				this.$data.error_message = 'Payor Bank is missing from the field.';
				this.createDialog = false;
			} else if(diffCurrency.length != 0){
				this.snackbar = true;
				this.$data.error_message = 'Transaction detail currency is different from header.';
				this.createDialog = false;
			} else {
				switch(method) {
				case 'save-regulartrx':
					this.$store.dispatch('regularTxns/saveregularTxns', payload);
					break;
				case 'update-regulartrx':
					this.$store.dispatch('regularTxns/updateregularTxns', payload);
					break;
				case 'save-cash-receipt':
					this.$store.dispatch('trxdisb/saveTrxdisb', {value: convertedFormData, type: payload.headers[0].document_type, distData: this.dist_data});
					break;
				case 'save-cash-receipt-draft':
					this.$store.dispatch('trxdisb/saveTrxdisb', {value: convertedFormData, type: payload.headers[0].document_type, distData: this.dist_data});
					break;
				}
			}
		},
		async setBatchNumber(id) { 
			let distData = this.dist_data;

			let date = this.getCurrentDate();
			let data = {
				document_date: date,
				edit_by: this.currUser.id
			};

			let unique_id = '';

			if('cash_receipt' in distData){
				this.setExistingBatchDetails('cash_receipt', distData, date);
			} else {
				data['document_type'] = 'CR';
				await this.$store.dispatch('trxdisb/getBatchNumber', data ).then(response => {
					let data = distData.cash_receipt;
					data['batch_number'] = response.data.data.batch_number;
					data['disbursement_type'] == id;
					this.$data.batch_number = response.data.data.batch_number;
				}).catch(error => {
					this.error_message = error;
				});

				if (!unique_id) {
					await this.$store.dispatch('trxdisb/getDocumentNumber', data).then(response => {
						unique_id = parseInt(response.data.data.document_number);
					}, error => {
						this.error_message = error;
					});
				}

				this.setBatchDetails('cash_receipt', distData, data, date, unique_id, id);
			}
		},
		async setBatchDetails(disb_type, dist_data, payload, current_date, unique_id, id) {
			await this.$store.dispatch('trxdisb/getBatchNumber', payload).then(response => {

				dist_data[disb_type] = {};
				let data = dist_data[disb_type];
				data['batch_number'] = response.data.data.batch_number;
				data['disbursement_type'] == id;
				this.$data.batch_number = response.data.data.batch_number;
			}, error => {
				this.error_message = error;
			});

			dist_data[disb_type]['batch_number'] = this.$data.batch_number;
			payload['batch_number'] = this.$data.batch_number;

			dist_data[disb_type]['document_number'] = [unique_id];
			dist_data[disb_type]['disbursement_type'] = id;
			dist_data[disb_type]['headers'] = [{
				company_id: 1,
				batch_number: '',
				regular_trx_type: '1',
				disbursement_type: '1',
				regular_trx_selected: '',
				document_number: unique_id,
				payee_bank: [],
				atc_code: 'WC100',
				currency_id: this.currency_id,
				dollar_rate: this.dollar_rate,
				vat: '12',
				amount: '20000',
				regular_trx_id: '0',
			}];

			dist_data[disb_type]['details'] = [{
				section_id: 1,
				regionalhead_id: 1,
				document_number: unique_id,
				document_date: this.$data.date,
				account_code: '',
				debit_amount: 0,
				credit_amount: 0,
			}];

			this.newDetail = { headers: dist_data[disb_type].headers, details: dist_data[disb_type].details};
			this.$store.commit('trxdisb/SET_DIST_DATA', dist_data);
			this.$store.commit('app/SET_IS_LOADING', false, { root: true });
		},
		async getDetails(type, receipt_type ){
			let receiptType = receipt_type.toLowerCase();
			let date = this.getCurrentDate();
			let head = [];
			let det = [];
			let payload = { document_date : this.$data.date };
			if(receiptType in this.dist_data['cash_receipt']){
				if(type == 'official_receipt'){
					if(this.acknowledgement_receipt == '1'){
						this.dist_data.cash_receipt.ar.headers.forEach(header => {
							head.push(header);
						});
						this.dist_data.cash_receipt.ar.details.forEach(detail => {
							det.push(detail);
						});
					}
					this.dist_data.cash_receipt[receiptType].headers.forEach(header => {
						head.push(header);
					});
					this.dist_data.cash_receipt[receiptType].details.forEach(detail => {
						det.push(detail);
					});

					this.newDetail = { headers: head, details: det };
					this.$store.commit('trxdisb/SET_DIST_DATA', this.dist_data);
				} else if (type == 'acknowledgement_receipt'){
					if(this.official_receipt == 1){
						this.dist_data.cash_receipt.or.headers.forEach(header => {
							head.push(header);
						});
						this.dist_data.cash_receipt.or.details.forEach(detail => {
							det.push(detail);
						});
					}

					this.dist_data.cash_receipt[receiptType].headers.forEach(header => {
						head.push(header);
					});
					this.dist_data.cash_receipt[receiptType].details.forEach(detail => {
						det.push(detail);
					});
					
					this.newDetail = { headers: head, details: det };
					this.$store.commit('trxdisb/SET_DIST_DATA', this.dist_data);
				}else{
					this.newDetail = { headers: this.dist_data.cash_receipt[receiptType].headers, details: this.dist_data.cash_receipt[receiptType].details };
					this.$store.commit('trxdisb/SET_DIST_DATA', this.dist_data);
				}
			} else {
				let unique_id = '';
				
				this.$store.commit('app/SET_IS_LOADING', true, { root: true });
				if(type === 'official_receipt'){
					let docNum = this.$store.dispatch('trxdisb/getDocumentNumberOR', payload).then(response => {
						if(response.data.data.length !== 0){
							unique_id = response.data.data[0].or_number;
						}
					}, error => {
						this.error_message = error;
					});

					await docNum;
				} else {
					let docNum = this.$store.dispatch('trxdisb/getDocumentNumberAR', payload).then(response => {
						if(response.data.data.length !== 0){
							unique_id = response.data.data[0].ar_number;
						}
					}, error => {
						this.error_message = error;
					});

					await docNum;
				}
				this.$store.commit('app/SET_IS_LOADING', false, { root: true });

				if(!unique_id){
					this.snackbar = true;
					this.$data.error_message = 'No series for ' + receipt_type + '.';
					this[type] = 0;
				} else {
					let details = [];
					let headers = [];
					const newDet = [{
						section_id: 1,
						regionalhead_id: 1,
						document_number: unique_id,
						type: receipt_type,
						document_date: date,
						account_code: '',
						debit_amount: 0,
						credit_amount: 0,
					}];
					const newHead = [{
						company_id: 1,
						batch_number: '',
						regular_trx_type: '1',
						disbursement_type: '1',
						regular_trx_selected: '',
						document_number: unique_id,
						payee_bank: [],
						atc_code: 'WC100',
						currency_id: this.currency_id,
						dollar_rate: this.dollar_rate,
						vat: '12',
						amount: '20000',
						regular_trx_id: '0',
					}];
					if(type == 'official_receipt'){
						if(this.acknowledgement_receipt == '1'){
							this.headers.forEach(head => {
								headers.push(head);
							});
							this.details.forEach(det => {
								details.push(det);
							});
						}
						headers.push(newHead[0]);
						details.push(newDet[0]);
					} else if (type == 'acknowledgement_receipt'){
						if(this.official_receipt == 1){
							this.headers.forEach(head => {
								headers.push(head);
							});
							this.details.forEach(det => {
								details.push(det);
							});
						}
						headers.push(newHead[0]);
						details.push(newDet[0]);
					}
					this.dist_data.cash_receipt[receiptType] = {};
					this.dist_data.cash_receipt[receiptType]['details'] = newDet;
					this.dist_data.cash_receipt[receiptType]['headers'] = newHead;
					this.newDetail = { headers: headers, details: details };
					this.$store.commit('trxdisb/SET_DIST_DATA', this.dist_data);
				}
			}
		},
		async getDocument(type, value){
			if(type === 'NR' && value == 1){
				this.official_receipt = 0;
				this.acknowledgement_receipt = 0;
				this.nr_readonly = true;
				this.getDetails('no_receipt', type);
			} else if(type == 'OR' && value == 1){
				this.no_receipt = 0;
				this.acknowledgement_receipt = 0;
				this.getDetails('official_receipt', type);
				this.nr_readonly = false;
			} else if (type == 'AR' && value == 1){
				this.no_receipt = 0;
				this.official_receipt = 0;
				this.getDetails('acknowledgement_receipt', type);
				this.nr_readonly = false;
			} 

			if(this.official_receipt == 0 && this.acknowledgement_receipt == 0){
				this.no_receipt = '1';
			} else if(type == 'OR' && value == 0 && this.acknowledgement_receipt == 1){
				let index = this.details.findIndex(i => i.type === 'OR');
				this.headers.splice(index, 1);
				this.details.splice(index, 1);
			} else if(type == 'AR' && value == 0 && this.official_receipt == 1){
				let index = this.details.findIndex(i => i.type === 'AR');
				this.headers.splice(index, 1);
				this.details.splice(index, 1);
			}
		},
		async getAcctperiodmain(){
			this.dataAcctmainperiod = this.acctperiodmains.data;
			this.nextPageAcctmainperiod= this.acctperiodmains.next_page_url;
			let page = 2;

			while (this.nextPageAcctmainperiod !== null){
				await this.$store.dispatch('acctperiodmain/getData', page).then(response => {
					this.nextPageAcctmainperiod = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataAcctmainperiod.push(details);
					});
				});
				page++;
			}
		},
		async getDataDepts(){
			this.dataDepts = this.depts.data;
			this.nextPageDepts = this.depts.next_page_url;
			let page = 2;

			while (this.nextPageDepts !== null){
				await this.$store.dispatch('dept/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDepts.push(details);
					});
				});
				page++;
			}
			let dept = this.dataDepts.filter(e => e.dept_status === 1);
			this.dataDepts = dept;
		},
		async getDataPayee(){
			this.dataPayee = this.payees.data;
			this.nextPagePayee= this.payees.next_page_url;
			let page = 2;

			while (this.nextPagePayee !== null){
				await this.$store.dispatch('payefile/getData', page).then(response => {
					this.nextPagePayee = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataPayee.push(details);
					});
				});
				page++;
			}
		},
		async getDataBank(){
			this.dataBanks = this.banks.data;
			this.nextPageBanks = this.banks.next_page_url;
			let page = 2;

			while (this.nextPageBanks !== null){
				await this.$store.dispatch('bank/getData', page).then(response => {
					this.nextPageBanks = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataBanks.push(details);
					});
				});
				page ++;
			}
			
			this.dataBanks.forEach(det => {
				det['code_name'] = det.bank_code + ' - ' + det.bank_acct;
			});
		},
		async getRegTrx(){
			this.dataRegularTrx = this.regulartrx.data;
			this.nextPageRegularTrx= this.regulartrx.next_page_url;
			let page = 2;

			while (this.nextPageRegularTrx !== null){
				await this.$store.dispatch('regularTxns/getData', page).then(response => {
					this.nextPageRegularTrx = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataRegularTrx.push(details);
					});
				});
				page++;
			}
		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				
				let data = this.dataCoas.filter(e => parseInt(e.acct_status) === 1);
				this.$store.commit('coa/SET_COA_DATA', data);
			}
		},
		getStatus(type, auto_debit){
			let status = 0;
			if(type === 'create'){
				status = 3;
				if(auto_debit === '1'){
					status = 6;
				} else if (this.currUser.max_amount !== null && parseFloat(this.currUser.max_amount) === parseFloat(this.level1.max_amount)){
					status = 4;
				} else if (this.currUser.max_amount !== null && parseFloat(this.currUser.max_amount) === parseFloat(this.level2.max_amount)){
					status = 5;
				}
			}else{
				status = 1;
			}
			return status;
		}
	}
};
</script>
<style scoped>
	.create-disb-title {
		color: #FFFFFF;
	}

	.card-action-container {
		display: flex;
		flex-direction: row-reverse;
	}

	.v-card .v-divider.create-disb-divider {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
</style>  
